import {
    asLeftTestTableSectionData, asLeftTestTableSectionDataP72, asLeftTestTableSectionDataP73,
    asLeftTestTableSectionDataP75, AsLeftTestTableSectionDataP77, AsLeftTestTableSectionDataP710andP68,
    asLeftTestTableSectionDataP63andP632, AsLeftTestTableSectionDataP65andP831, asLeftTestTableSectionDataP61andP612,
    AsLeftTestTableSectionDataP622, AsLeftTestTableSectionDataP67P841P8101, AsLeftTestTableSectionDataP610,
    AsLeftTestTableSectionDataP66, AsLeftTestTableSectionDataP851, AsLeftTestTableSectionDataM613,
    AsLeftTestTableSectionDataM67, AsLeftTestTableSectionDataM69, AsLeftTestTableSectionDataM68, AsLeftTestTableSectionDataM63,
    AsLeftTestTableSectionDataM612, AsLeftTestTableSectionDataM64, AsLeftTestTableSectionDataM610,
    AsLeftTestTableSectionDataM66, AsLeftTestTableSectionDataM65, AsLeftTestTableSectionDataM31,
    AsLeftTestTableSectionDataP611, AsLeftTestTableSectionDataP724,
    AsLeftTestTableSectionDataP726, AsLeftTestTableSectionDataP8141
} from './Data';
import { AsLeftTestTableSectionDataP716 } from './Data/P716/P716.data';
import { AsLeftTestTableSectionDataP723 } from './Data/P723/P723.data';
import { asLeftTestTableSectionDataP871 } from './Data/P871/P871.data';
import { AsLeftTestTableSectionDataP728 } from './Data/P728/P728.data';
import { asLeftTestTableSectionDataP74 } from './Data/P74/P74.data';
import { AsLeftTestTableSectionDataP727 } from './Data/P727/P727.data';
import { AsLeftTestTableSectionDataP615 } from './Data/P615/P615.data';
import { AsLeftTestTableSectionDataP732 } from './Data/P732/P732.data';
import { AsLeftTestTableSectionDataP891 } from './Data/P891/P891.data';
import { AsLeftTestTableSectionDataP725 } from './Data/P725/P725.data';
import { AsLeftTestTableSectionDataP733P620 } from './Data/P733/P733.data';
import { AsLeftTestTableSectionDataP8111 } from './Data/P8111/P8111.data';
import { AsLeftTestTableSectionDataM616 } from './Data/M616/M616.data';
import { AsLeftTestTableSectionDataM71 } from './Data/M71/M71.data';
import { AsLeftTestTableSectionDataP720 } from './Data/P720/P720.data';
import { AsLeftTestTableSectionDataP721 } from './Data/P721/P721.data';
import { AsLeftTestTableSectionDataP711 } from './Data/P711/P711.data';
import { AsLeftTestTableSectionDataM611M614M615M61 } from './Data/M611/M611.data';
import { AsLeftTestTableSectionDataP6022 } from './Data/P6022/P6022.data';
import { AsLeftTestTableSectionDataS72 } from './Data/S72/S72.data';
import { AsLeftTestTableSectionDataS71 } from './Data/S71/S71.data';
import { AsLeftTestTableSectionDataP625 } from './Data/P625/P625.data';
import { AsLeftTestTableSectionDataP630 } from './Data/P630/P630.data';

export const selectAsLeftTestTableSectionData = (selectedTag, formName, formData, altTestPoint) => {
    const formNameToFunctionMap = {
        'P7-1': () => asLeftTestTableSectionData(selectedTag, formData),
        'P7-2': () => asLeftTestTableSectionDataP72(selectedTag, formData),
        'P7-3': () => asLeftTestTableSectionDataP73(selectedTag, formData),
        'P7-5': () => asLeftTestTableSectionDataP75(selectedTag, formData),
        'P7-7': () => AsLeftTestTableSectionDataP77(selectedTag, formData),
        'P7-9': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-18': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-7': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-4.1': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-10.1': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-13': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-16': () => AsLeftTestTableSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-10': () => AsLeftTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-8': () => AsLeftTestTableSectionDataP710andP68(selectedTag, formData),
        'P7-8': () => AsLeftTestTableSectionDataP710andP68(selectedTag, formData),
        'P7-12': () => AsLeftTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-17': () => AsLeftTestTableSectionDataP710andP68(selectedTag, formData),
        'P6-10': () => AsLeftTestTableSectionDataP610(selectedTag, formData),
        'P7-6': () => AsLeftTestTableSectionDataP610(selectedTag, formData),
        'P6-5': () => AsLeftTestTableSectionDataP65andP831(selectedTag, formData),
        'P6-5.2': () => AsLeftTestTableSectionDataP65andP831(selectedTag, formData),
        'P8-3.1': () => AsLeftTestTableSectionDataP65andP831(selectedTag, formData),
        'P6-1': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-1.2': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P8-1.1': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-2': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P8-2.1': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-4': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-4.2': () => asLeftTestTableSectionDataP61andP612(selectedTag, formData),
        'P6-3': () => asLeftTestTableSectionDataP63andP632(selectedTag, formData),
        'P6-3.2': () => asLeftTestTableSectionDataP63andP632(selectedTag, formData),
        'P6-2.2': () => AsLeftTestTableSectionDataP622(selectedTag, formData),
        'P6-6': () => AsLeftTestTableSectionDataP66(selectedTag, formData),
        'P8-17.1': () => AsLeftTestTableSectionDataP66(selectedTag, formData),
        'P8-5.1': () => AsLeftTestTableSectionDataP851(selectedTag, formData),
        'M6-13': () => AsLeftTestTableSectionDataM613(selectedTag, formData),
        'M6-7': () => AsLeftTestTableSectionDataM67(selectedTag, formData),
        'M6-8': () => AsLeftTestTableSectionDataM68(selectedTag, formData),
        'M6-9': () => AsLeftTestTableSectionDataM69(selectedTag, formData),
        'M6-6': () => AsLeftTestTableSectionDataM66(selectedTag, formData),
        'M6-10': () => AsLeftTestTableSectionDataM610(formData, altTestPoint),
        'M6-11': () => AsLeftTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-14': () => AsLeftTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-15': () => AsLeftTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-1': () => AsLeftTestTableSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-3': () => AsLeftTestTableSectionDataM63(selectedTag, formData),
        'M6-4': () => AsLeftTestTableSectionDataM64(selectedTag, formData),
        'M6-12': () => AsLeftTestTableSectionDataM612(selectedTag, formData),
        'M6-5': () => AsLeftTestTableSectionDataM65(selectedTag, formData),
        'M3-1': () => AsLeftTestTableSectionDataM31(selectedTag, formData),
        'P6-11': () => AsLeftTestTableSectionDataP611(selectedTag, formData),
        'P7-17': () => AsLeftTestTableSectionDataP611(selectedTag, formData),
        'P7-33': () => AsLeftTestTableSectionDataP733P620(selectedTag, formData),
        'P6-20': () => AsLeftTestTableSectionDataP733P620(selectedTag, formData),
        'P7-23': () => AsLeftTestTableSectionDataP723(selectedTag, formData),
        'P7-16': () => AsLeftTestTableSectionDataP716(selectedTag, formData),
        'P6-13': () => AsLeftTestTableSectionDataP716(selectedTag, formData),
        'P8-7.1': () => asLeftTestTableSectionDataP871(selectedTag, formData),
        'P7-28': () => AsLeftTestTableSectionDataP728(selectedTag, formData),
        'P7-24': () => AsLeftTestTableSectionDataP724(selectedTag, formData),
        'P7-4': () => asLeftTestTableSectionDataP74(selectedTag, formData),
        'P7-4.1': () => asLeftTestTableSectionDataP74(selectedTag, formData),
        'P7-25': () => AsLeftTestTableSectionDataP725(selectedTag, formData),
        'P7-26': () => AsLeftTestTableSectionDataP726(selectedTag, formData),
        'P7-27': () => AsLeftTestTableSectionDataP727(selectedTag, formData),
        'P6-15': () => AsLeftTestTableSectionDataP615(selectedTag, formData),
        'P7-32': () => AsLeftTestTableSectionDataP732(selectedTag, formData),
        'P8-9.1': () => AsLeftTestTableSectionDataP891(selectedTag, formData),
        'P7-19': () => AsLeftTestTableSectionDataP891(selectedTag, formData),
        'P8-11.1': () => AsLeftTestTableSectionDataP8111(selectedTag, formData),
        'M6-16': () => AsLeftTestTableSectionDataM616(selectedTag, formData),
        'M7-1': () => AsLeftTestTableSectionDataM71(formData),
        'P7-20': () => AsLeftTestTableSectionDataP720(formData, altTestPoint),
        'P6-26': () => AsLeftTestTableSectionDataP720(formData, altTestPoint),
        'P7-21': () => AsLeftTestTableSectionDataP721(formData, altTestPoint),
        'P7-11': () => AsLeftTestTableSectionDataP711(selectedTag, formData),
        'P8-13.1': () => AsLeftTestTableSectionDataP711(selectedTag, formData),
        'P8-14.1': () => AsLeftTestTableSectionDataP8141(formData),
        'P6-29': () => AsLeftTestTableSectionDataP8141(formData),
        'P6-22': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-22': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-15': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-14': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P6-31': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-18': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P6-27': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P6-12': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-29': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P8-8.1': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P8-12.1': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'P7-30': () => AsLeftTestTableSectionDataP6022(selectedTag, formData, formName),
        'S7-2': () => AsLeftTestTableSectionDataS72(formName, formData),
        'S7-3': () => AsLeftTestTableSectionDataS72(formName, formData),
        'S7-4': () => AsLeftTestTableSectionDataS72(formName, formData),
        'P6-25': () => AsLeftTestTableSectionDataP625(formData),
        'P6-30': () => AsLeftTestTableSectionDataP630(formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Partial') {
        return AsLeftTestTableSectionDataS71(formData);
    }
    return filterViewData;
};
